/* global gtag */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import zxcvbn from 'zxcvbn';
import './ChangePasswordModal.css';
import invisible from '../assets/images/invisible.svg';
import visible from '../assets/images/visible.svg';

const ChangePasswordModal = ({ isOpen, onClose, setIsLoading }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    const result = zxcvbn(newPassword);
    setPasswordStrength(result.score);
  }, [newPassword]);

  useEffect(() => {
    if (isOpen && window.gtag) {
      window.gtag('event', 'change_password_initiated', {
        'event_category': 'Security',
        'event_label': 'Change Password Modal Opened',
        'value': 1,
      });
    }
  }, [isOpen]);

  const resetModal = () => {
    setCurrentPassword('');
    setNewPassword('');
    setPasswordError('');
    setPasswordSuccess('');
    setPasswordStrength(0);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const token = localStorage.getItem('token');
    if (passwordStrength < 2) {
      setPasswordError('Password is too weak');
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post('/api/change-password', { currentPassword, newPassword }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setPasswordSuccess(response.data);
      setPasswordError('');
      setCurrentPassword('');
      setNewPassword('');

      // Track password change success
      if (window.gtag) {
        window.gtag('event', 'change_password_success', {
          'event_category': 'Security',
          'event_label': 'Password Changed Successfully',
          'value': 1,
        });
      }

      setTimeout(() => {
        onClose();
        resetModal();
      }, 3000); // Keep modal open for 3 seconds to show success message
    } catch (err) {
      setPasswordError(err.response?.data || 'Failed to update password');
      setPasswordSuccess('');

      // Track password change failure
      if (window.gtag) {
        window.gtag('event', 'change_password_failure', {
          'event_category': 'Security',
          'event_label': 'Password Change Failed',
          'value': 1,
        });
      }
      } finally {
      setIsLoading(false);
    }
  };

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="change-password-modal">
        <h3>Change Password</h3>
        <form onSubmit={handleChangePassword}>
          <div className="form-group">
            <label htmlFor="currentPassword">Current Password:</label>
            <div className="password-wrapper">
              <input
                type={showCurrentPassword ? 'text' : 'password'}
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
              <img
                src={showCurrentPassword ? visible : invisible}
                alt="Toggle visibility"
                className="toggle-password"
                onClick={toggleShowCurrentPassword}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <div className="password-wrapper">
              <input
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <img
                src={showNewPassword ? visible : invisible}
                alt="Toggle visibility"
                className="toggle-password"
                onClick={toggleShowNewPassword}
              />
            </div>
            <div className="password-strength">
              <div className={`strength-meter strength-${passwordStrength}`} />
              <p className="strength-text">
                {passwordStrength === 0 && 'Very Weak'}
                {passwordStrength === 1 && 'Weak'}
                {passwordStrength === 2 && 'Fair'}
                {passwordStrength === 3 && 'Good'}
                {passwordStrength === 4 && 'Strong'}
              </p>
            </div>
          </div>
          <button type="submit" className="btn-save">Change Password</button>
          {passwordError && <p className="error">{passwordError}</p>}
          {passwordSuccess && <p className="success">{passwordSuccess}</p>}
        </form>
        <button onClick={() => { onClose(); resetModal(); }} className="btn-close">Cancel</button>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
