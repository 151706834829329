// NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => {
  return (
    <div className="notfound-page">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist. It might have been moved or deleted.</p>
      <Link to="/" className="btn">Go to Homepage</Link>
    </div>
  );
};

export default NotFoundPage;
