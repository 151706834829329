/* global gtag */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import './ResetPasswordPage.css';
import visibleIcon from '../assets/images/visible.svg';
import invisibleIcon from '../assets/images/invisible.svg';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    const result = zxcvbn(newPassword);
    setPasswordStrength(result.score);
  }, [newPassword]);

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  }, [newPassword, confirmPassword]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (passwordError) return; // Prevent submission if there is an error

    // Track the password reset attempt
    if (window.gtag) {
      window.gtag('event', 'password_reset_attempt', {
        'event_category': 'User',
        'event_label': 'Password Reset Attempted',
        'value': 1,
      });
    }

    try {
      const response = await axios.post('/api/reset-password', { token, newPassword });
      setPasswordSuccess(response.data);
      setPasswordError('');

      // Track the password reset success
      if (window.gtag) {
        window.gtag('event', 'password_reset_success', {
          'event_category': 'User',
          'event_label': 'Password Reset Successful',
          'value': 1,
        });
      }

      setTimeout(() => {
        navigate('/register-login'); // Redirect to login after success
      }, 3000);
    } catch (err) {
      setPasswordError(err.response?.data || 'Failed to reset password');
      setPasswordSuccess('');

      // Track the password reset failure
      if (window.gtag) {
        window.gtag('event', 'password_reset_failure', {
          'event_category': 'User',
          'event_label': 'Password Reset Failed',
          'value': 1,
        });
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="reset-password-page-container">
      <div className="reset-password-page">
        <h2>Reset Password</h2>
        {passwordError && <p className="error">{passwordError}</p>}
        {passwordSuccess && <p className="success">{passwordSuccess}</p>}
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label>New Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <img
                src={showPassword ? visibleIcon : invisibleIcon}
                alt="Toggle visibility"
                className="toggle-password"
                onClick={toggleShowPassword}
              />
            </div>
            <div className="password-strength">
              <div className={`strength-meter strength-${passwordStrength}`} />
              <p className="strength-text">
                {passwordStrength === 0 && 'Very Weak'}
                {passwordStrength === 1 && 'Weak'}
                {passwordStrength === 2 && 'Fair'}
                {passwordStrength === 3 && 'Good'}
                {passwordStrength === 4 && 'Strong'}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <div className="password-wrapper">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <img
                src={showConfirmPassword ? visibleIcon : invisibleIcon}
                alt="Toggle visibility"
                className="toggle-password"
                onClick={toggleShowConfirmPassword}
              />
            </div>
          </div>
          <button type="submit" className="reset-btn">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
