import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './WinningsCounter.css';

const WinningsCounter = () => {
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get('/api/winnings-counter');
        setBalance(Math.floor(response.data.balance));
      } catch (error) {
        setError('Failed to load balance');
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, []);

  if (loading) {
    return <div className="winnings-counter">Loading...</div>;
  }

  if (error) {
    return <div className="winnings-counter">{error}</div>;
  }

  return (
    <>
    <div className="winnings-header">
      <h1>CURRENT PRIZE POOL</h1>
    </div>
    <div className="winnings-counter">
      <div className="winnings-amount">{balance !== null ? balance.toLocaleString() : '0'} USDT</div>
    </div>
    </>
  );
};

export default WinningsCounter;
