import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
//import { useLocation } from 'react-router-dom';  // Import useLocation to access query parameters
import WinningsCounter from '../components/WinningsCounter';
import CountdownTimer from '../components/CountdownTimer';
import WinnersTable from '../components/WinnersTable';
import './HomePage.css';
import homeLogo from '../assets/images/Logo_Homepage.svg';
import preDrawVideo from '../assets/videos/PreDrawVideo.mp4';
import postDrawVideo from '../assets/videos/PostDrawVideo.mp4';

function HomePage() {
  const [isDrawTime, setIsDrawTime] = useState(false);
  const [isPostDraw, setIsPostDraw] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [displayIndex, setDisplayIndex] = useState([]);
  const [timeLeft, setTimeLeft] = useState({});
  const [nextDrawDate, setNextDrawDate] = useState(null);
  const [sequenceReady, setSequenceReady] = useState(false);
  const [drawDetails, setDrawDetails] = useState({
    ticketsSold: 0,
    usersParticipated: 0,
    prizeMoney: 0,
    winningNumbers: [],
    winners: [],
    nextDrawDate: '',
    totalUsers: 0,
  });

  const videoRef = useRef(null);
  //const location = useLocation();  // Access the location object to get the query parameters

  const formatDate = (dateString) => {
    if (!dateString) {
      return "Date not available";
    }

    const date = new Date(dateString);

    // Ensure the date is valid
    if (isNaN(date.getTime())) {
      return "Date not valid";
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC',
      timeZoneName: 'short',
    });

    return formatter.format(date).replace('UTC', 'GMT');
  };

  const sequenceTiming = [
    // PreDrawVideo sequences
    {
      start: 1500,
      end: 10000,
      text: 'Welcome to this weeks draw!',
      style: {
        top: '0%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '100%',
      },
    },
    {
      start: 6000,
      end: 14000,
      text: `Total Tickets: ${drawDetails.ticketsSold}`,
      style: {
        top: '10%',
        left: '80%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '25%',
      },
    },
    {
      start: 8500,
      end: 14000,
      text: `Users Participated: ${drawDetails.usersParticipated}`,
      style: {
        top: '35%',
        left: '80%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '25%',
      },
    },
    {
      start: 10500,
      end: 14000,
      text: `Prize: ${drawDetails.prizeMoney.toLocaleString()} USDT`,
      style: {
        top: '65%',
        left: '80%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '25%',
      },
    },
    {
      start: 15000,
      end: 22000,
      text: `Average Tickets / User: ${(drawDetails.ticketsSold / drawDetails.usersParticipated).toFixed(2)}`,
      style: {
        top: '10%',
        left: '15%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '25%',
      },
    },
    {
      start: 17000,
      end: 22000,
      text: `Participation Rate: ${(((drawDetails.usersParticipated / drawDetails.totalUsers) * 100).toFixed(1))}%`,
      style: {
        top: '35%',
        left: '15%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '25%',
      },
    },
    {
      start: 19000,
      end: 22000,
      text: `Chance to Win: ${((1 / (drawDetails.ticketsSold / drawDetails.usersParticipated)) * 100).toFixed(2)}%`,
      style: {
        top: '65%',
        left: '15%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '25%',
      },
    },
    {
      start: 24000,
      end: 55000,
      text: '',
      style: {
        top: '0%',
        left: '50%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '50%',
      },
    },
    {
      start: 60000,
      end: 67000,
      text: 'Time to draw the winning numbers!',
      style: {
        top: '50%',
        left: '5%',
        transform: 'translateY(-50%)',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '20%',
      },
    },
    {
      start: 67000,
      end: 91000,
      text: 'The Winning Numbers Are',
      style: {
        top: '0%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: '100%',
      },
    },
    {
      start: 69000,
      end: 99000,
      text: `${drawDetails.winningNumbers.number1}`,
      style: {
        top: '90%',
        bottom: '1%',
        left: '25%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: 'auto',
        maxHeight: '10%',
      },
    },
    {
      start: 71000,
      end: 99000,
      text: `${drawDetails.winningNumbers.number2}`,
      style: {
        top: '90%',
        bottom: '1%',
        left: '35%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: 'auto',
        maxHeight: '10%',
      },
    },
    {
      start: 73000,
      end: 99000,
      text: `${drawDetails.winningNumbers.number3}`,
      style: {
        top: '90%',
        bottom: '1%',
        left: '45%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: 'auto',
        maxHeight: '10%',
      },
    },
    {
      start: 75000,
      end: 99000,
      text: `${drawDetails.winningNumbers.number4}`,
      style: {
        top: '90%',
        bottom: '1%',
        left: '55%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: 'auto',
        maxHeight: '10%',
      },
    },
    {
      start: 77000,
      end: 99000,
      text: `${drawDetails.winningNumbers.number5}`,
      style: {
        top: '90%',
        bottom: '1%',
        left: '65%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: 'auto',
        maxHeight: '10%',
      },
    },
    {
      start: 79000,
      end: 99000,
      text: `${drawDetails.winningNumbers.number6}`,
      style: {
        top: '90%',
        bottom: '1%',
        left: '75%',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#000',
        width: 'auto',
        maxHeight: '10%',
      },
    },
    {
      start: 92000,
      end: 113000,
      text: `Congratulations to: ${drawDetails.winners.join(', ')}`,
      style: {
        top: '0%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
        color: '#ffd700',
        width: '100%',
      },
    },
    {
      start: 100000,
      end: 113000,
      text: `Didn’t win this time? Don’t worry! Your next chance is ${formatDate(drawDetails.nextDrawDate)}. Buy your tickets now and try your luck in the next draw!`,
      style: {
        top: '85%', // Hugging the bottom of the screen
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: 'clamp(0.8rem, 1.2vw, 1.2rem)',  // Slightly smaller for last sequence item
        width: '95%',
        height: 'auto',
        maxHeight: "10%",
        color: '#000',
      },
    },
  ];

  // TEST: Check for query parameter to trigger video
  //useEffect(() => {
  //  const queryParams = new URLSearchParams(location.search);
  //  if (queryParams.get('startDraw') === 'true') {
  //    handleDrawTimeChange(true);  // Trigger the draw time if the URL contains startDraw=true
  //  }
  //}, [location.search]);

  // Function to fetch draw details before the draw
  const fetchBeforeDrawDetails = async () => {
    try {
      const response = await axios.post('/api/before-draw-details', { drawDate: nextDrawDate });
      const { ticketsSold, usersParticipated, prizeMoney, totalUsers, hasTickets } = response.data;

      // Check if there are tickets sold
      if (!hasTickets) {
        console.log('No tickets sold for this draw. Video will not play. Draw Date: ', nextDrawDate);
        setShowVideo(false);

        return;
      }

      // If tickets are sold, update the state
      setDrawDetails((prevDetails) => ({
        ...prevDetails,
        ticketsSold,
        usersParticipated,
        prizeMoney,
        totalUsers,
      }));

      // Start polling for after-draw details
      const currentTimeInMinutes = new Date().getUTCHours() * 60 + new Date().getUTCMinutes();
      const drawEndTime = currentTimeInMinutes + 1;

      // Start polling for after-draw details if Draw has tickets
      if(hasTickets) {
        const interval = setInterval(() => {
          const currentTime = new Date().getUTCHours() * 60 + new Date().getUTCMinutes();
          //Put back after testing const drawEndTime = 17 * 60 + 1;  // 17:01 in minutes
          if (currentTime >= drawEndTime) {
            fetchAfterDrawDetails();
            clearInterval(interval);
          }
        }, 30000);  // Check every 30 seconds
      }
    } catch (error) {
      console.error('Error fetching before draw details:', error);
    }
  };

  // Function to fetch draw details after the draw
  const fetchAfterDrawDetails = async () => {
    try {
      const response = await axios.post('/api/after-draw-details', { drawDate: nextDrawDate });
      const { winningNumbers, winners, nextDrawDate } = response.data;

      setDrawDetails((prevDetails) => ({
        ...prevDetails,
        winningNumbers,
        winners,
        nextDrawDate,
      }));

    // Set flag to indicate sequence can continue with post-draw data
    setSequenceReady(true);  // Set flag to true once data is available
    } catch (error) {
      console.error('Error fetching after draw details:', error);
    }
  };

  // Function to handle when a video ends
  const handleVideoEnd = () => {
    // Check if the current video is the pre-draw video
    if (videoRef.current.src.includes(preDrawVideo)) {
      // Switch to the post-draw video
      setIsPostDraw(true);
      videoRef.current.src = postDrawVideo;
      videoRef.current.play();
    } else {
        // Reset states only after the post-draw video ends
      setIsPostDraw(false);
      setShowVideo(false);
      setSequenceReady(false);
    }
  };

  // Draw time change handler to update video state
  const handleDrawTimeChange = (isDrawTime) => {
    setIsDrawTime(isDrawTime);

    if (isDrawTime) {
      setShowVideo(true);
      fetchBeforeDrawDetails()
        .then(() => {
          // Don't do anything //console.log("Draw details fetched.");
        })
        .catch((error) => {
          console.error("Error fetching draw details:", error);
        });
      }
    };

  // Sequence timing logic using video playback time
  useEffect(() => {
    const updateSequence = () => {
      if (videoRef.current) {
        // Calculate the current time in the video (accounting for post-draw video offset)
        const currentTime = (isPostDraw ? 58000 : 0) + videoRef.current.currentTime * 1000;

        // Determine which sequence items should be visible based on the current time
        const activeItems = sequenceTiming.map((item, index) => {
          if (currentTime >= item.start && currentTime <= item.end) {
            return index;
          }
          return null;
        }).filter(index => index !== null);

        // Update the displayIndex state with the active sequence items
        setDisplayIndex(activeItems);
      }
    };

    // Ensure pre-draw sequences can run without waiting for winningNumbers
    if (drawDetails.ticketsSold > 0 && showVideo) {
      videoRef.current.addEventListener('timeupdate', updateSequence);
    }

    // Ensure post-draw sequences only run when data is ready
    if (sequenceReady) {
      videoRef.current.addEventListener('timeupdate', updateSequence);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', updateSequence);
      }
    };
  }, [isPostDraw, sequenceTiming, sequenceReady, drawDetails]);

  return (
    <div className="homepage">
      <div className="homepage-section">
        {showVideo ? (
          <div className="video-container">
            <video
              className="draw-video"
              ref={videoRef}
              src={preDrawVideo}
              autoPlay
              muted
              playsInline
              controls={false}
              onEnded={handleVideoEnd}
            />
            {sequenceTiming.map((item, index) => (
              <div
                key={index}
                className={`draw-info sequence-${index}`}
                style={{
                  ...item.style,
                  visibility: displayIndex.includes(index) ? 'visible' : 'hidden',
                }}
              >
                {index === 7
                  ? `Time Left Till Draw: ${timeLeft.seconds || 0}s`
                  : item.text}
              </div>
            ))}
          </div>
        ) : (
          <img
            src={homeLogo}
            className="homepage-logo"
            alt="WinCoinDraw Logo"
            style={{ visibility: showVideo ? 'hidden' : 'visible' }}
          />
        )}
      </div>

      <div className="homepage-section">
        <WinningsCounter />
      </div>

      <div className="homepage-section">
        <CountdownTimer onDrawTimeChange={handleDrawTimeChange} onTimeLeftChange={setTimeLeft} />
      </div>

      <div className="homepage-section">
        <WinnersTable />
      </div>

      <div className="homepage-section-bottom">
        <h2>Why Choose WinCoinDraw?</h2>
        <p>
          <br />
          WinCoinDraw offers a fully automated and transparent lottery experience. Buy lottery tickets using over 300 different cryptocurrencies, including Bitcoin, Ethereum, and more. Our weekly draws ensure a fair and secure chance to win. Join now and be a part of the most exciting crypto lottery!
        </p>
      </div>
    </div>
  );
}

export default HomePage;
