/* global gtag */
import React from 'react';
import './AboutPage.css';
import aboutHeader from '../assets/images/AboutHeaderImage.svg';
import background from '../assets/images/Background.svg';

const AboutPage = () => {
  const handleContactLinkClick = () => {
    if (window.gtag) {
      window.gtag('event', 'contact_link_click', {
        'event_category': 'Navigation',
        'event_label': 'Contact Link Clicked - About Page',
        'value': 1,
      });
    }
  };

  return (
    <div className="about-page" style={{ backgroundImage: `url(${background})` }}>
      <img src={aboutHeader} alt="About Us Header Image" className="about-header" />
      <section className="about-section story-section">
        <h2>Our Story</h2>
        <p>
          Welcome to WinCoinDraw, your premier destination for an innovative and exciting online lottery experience. Founded with a vision to revolutionize the way people engage with lottery games, WinCoinDraw leverages the power of blockchain technology to offer secure, transparent, and fair lottery draws. Our vision is to create a fully automated and transparent lottery experience where users can participate from anywhere in the world.
        </p>
      </section>

      <div className="about-grid">
      <section className="about-section">
        <h2>Our Mission</h2>
        <p>
          At WinCoinDraw, our mission is to provide a global platform where lottery enthusiasts can participate in draws with confidence. We believe in the transformative power of technology and strive to provide a seamless, user-friendly experience that upholds the highest standards of integrity and security.
        </p>
      </section>

      <section className="about-section">
        <h2>Our Vision</h2>
        <p>
          Our vision is to create a fully automated and transparent lottery experience where users can participate from anywhere in the world. We strive to make WinCoinDraw the most trusted and popular online crypto lottery platform, offering a seamless and enjoyable experience for all our users.
        </p>
      </section>

      <section className="about-section why-choose-us-section">
        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Transparency:</strong> Our blockchain-based system ensures that every draw is fair and verifiable. Users can see the live wallet holding the prize money and track all transactions related to prize payouts.</li>
          <li><strong>Security:</strong> Powered by NowPayments, we accept over 300 cryptocurrencies, providing fast and secure transactions. Our platform is fortified with advanced encryption methods to protect your data and transactions.</li>
          <li><strong>Global Access:</strong> Participate in our lottery from anywhere in the world using a wide range of cryptocurrencies.</li>
          <li><strong>Community Focused:</strong> A portion of our proceeds goes towards charitable causes, making every ticket a chance to give back.</li>
        </ul>
      </section>

      <section className="about-section team-section">
        <h2>Our Team</h2>
        <p>
          WinCoinDraw is powered by a team of passionate professionals dedicated to providing an exceptional lottery experience. Our team includes experts in blockchain technology, web development, digital marketing, and customer support, all working together to bring you the best in online lottery entertainment.
        </p>

        <h2>Join WinCoinDraw Today</h2>
        <p>
          Join WinCoinDraw today and become part of the most exciting and transparent online crypto lottery. Register now, buy your tickets, and stand a chance to win big every week. With our automated and secure system, you can participate with confidence and enjoy the thrill of winning cryptocurrency.
        </p>
      </section>

      <section className="about-section contact-section">
        <h2>Contact Us</h2>
        <p>
          Have questions or need assistance? Our support team is here to help. Reach out to us through our <a href="/contact" onClick={handleContactLinkClick}>Contact Page</a>.
        </p>
      </section>
      </div>
    </div>
  );
};

export default AboutPage;
