/* global gtag */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import ErrorModal from './ErrorModal';
import './Dashboard.css';
import ChangePasswordModal from './ChangePasswordModal';
import AccountDeletionModal from './AccountDeletionModal';
import TwoFAModal from './TwoFAModal';
import LoadingModal from './LoadingModal';
import upArrow from '../assets/images/chevron-arrow-up.png';
import downArrow from '../assets/images/chevron-arrow-down.png';
import dashboardHeaderImage from '../assets/images/dashboardHeaderImage.svg';
import firstPageIcon from '../assets/images/firstPageIcon.svg';
import prevPageIcon from '../assets/images/prevPageIcon.svg';
import nextPageIcon from '../assets/images/nextPageIcon.svg';
import lastPageIcon from '../assets/images/lastPageIcon.svg';

const Dashboard = ({ setIsLoggedIn }) => {
  const [user, setUser] = useState({});
  const [tickets, setTickets] = useState([]);
  //const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [username, setUsername] = useState('');
  const [wallet, setWallet] = useState('');
  const [currency, setCurrency] = useState('');
  const [email, setEmail] = useState('');
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(false);
  const [isManage2FAModalOpen, setIsManage2FAModalOpen] = useState(false);
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [is2FAModalOpen, setIs2FAModalOpen] = useState(false);
  const [twoFaCode, setTwoFaCode] = useState('');
  const [twoFaMethod, setTwoFaMethod] = useState('');
  const [modalState, setModalState] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const openAccountDeletionModal = () => {
    setIsAccountDeletionModalOpen(true);
  };

  const closeAccountDeletionModal = () => {
    setIsAccountDeletionModalOpen(false);
  };

  const openManage2FAModal = () => {
    setIsManage2FAModalOpen(true);
  };

  const closeManage2FAModal = (newMethod) => {
    if (newMethod) {
      setTwoFaMethod(newMethod);
    }
    setIsManage2FAModalOpen(false);
  };

  const handleAccountDeletion = () => {
    localStorage.removeItem('token');
    window.location.href = '/register-login';
  };

  const columns = React.useMemo(
  () => [
    {
      Header: 'Ticket Number',
      accessor: 'number'
    },
    {
      Header: 'Draw Date',
      accessor: 'draw_date',
      Cell: ({ value }) => new Date(value).toLocaleDateString('en-GB').replace(/\//g, '-')
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => {
        let color = '#1db5cb';

        if (value.toLowerCase() === 'lost') {
          color = '#FF0000';
        } else if (value.toLowerCase() === 'won'){
          color = '#008000';
        }

        return (
          <span style={{ color, fontWeight: 'bold' }}>
            {value.toUpperCase()}
          </span>
        );
      },
    },
  ],
  []
);

const data = React.useMemo(() => tickets.map(ticket => ({
  ...ticket,
  draw_date: new Date(ticket.draw_date).toISOString(), // Format as DD-MM-YYYY
  status: ticket.status.toUpperCase() // Convert status to uppercase
})), [tickets]);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  state,
  setGlobalFilter,
  page,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  gotoPage,
  pageCount,
  setPageSize
} = useTable(
  {
    columns,
    data,
    initialState: { sortBy: [{ id: 'draw_date', desc: true }] } // Set initial sort state here
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination
);

const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'view_dashboard', {
        'event_category': 'User',
        'event_label': 'Dashboard Viewed',
        'value': 1,
      });
    }
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Please log in.');
        setShowErrorModal(true);
        return;
      }

      try {
        const response = await axios.post('/api/user', {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUser(response.data.user);
        setTickets(response.data.tickets);
        //setNotifications(response.data.notifications);
        setUsername(response.data.user.username);
        setWallet(response.data.user.wallet);
        setCurrency(response.data.user.currency);
        setTwoFaMethod(response.data.user.two_fa_method);
        setEmail(response.data.user.email);
        const currenciesResponse = await axios.get('/api/supported-currencies');
        setSupportedCurrencies(currenciesResponse.data.supportedCurrencies.map(curr => ({ value: curr, label: curr.toUpperCase() })));
      } catch (err) {
        if (err.response && err.response.status === 403) {
          setError('Session expired. Please log in again.');
          setShowErrorModal(true);
        } else {
          setError('Failed to load user data');
        }
      }
    };

    fetchUserData();
  }, []);

  const checkSession = async () => {
    try {
      const response = await axios.post('/api/user', {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.status === 200; // Session is valid
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError('Session expired. Please log in again.');
        setShowErrorModal(true);
        return false; // Session is expired
      }
      return false;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    window.location.href = '/register-login'; // Redirect to login page

    if (window.gtag) {
      window.gtag('event', 'logout', {
        'event_category': 'User',
        'event_label': 'User Logged Out',
        'value': 1,
      });
    }
  };

  // Function to cancel 2FA modal
  function cancel2FA(){

    setIs2FAModalOpen(false);
  }

  const handleSensitiveAction = async (action) => {
    const sessionValid = await checkSession();
    if (!sessionValid) return;

    if(action === 'profile'){
      setEditMode(true);
    } else {
      handleModalState(action);
    }
  }

  function handleModalState(stateOfModal){
    setModalState(stateOfModal);
    if(stateOfModal === 'profile'){
      check2FAAndSaveProfile();
    } else if(stateOfModal === 'changePassword'){
      handle2FAForPasswordChange();
    } else if(stateOfModal === 'deleteAccount'){
      handle2FAForAccountDeletion();
    } else if(stateOfModal === 'manage2FA'){
      handle2FAForManagement();  // New function to handle 2FA management
    }
  }

  const handleVerify = () => {
    if(modalState === 'profile'){
      verify2FAAndSaveProfile();
    } else if(modalState === 'changePassword'){
      verify2FAAndChangePassword();
    } else if(modalState === 'deleteAccount'){
      verify2FAAndDeleteAccount();
    } else if(modalState === 'manage2FA'){
      verify2FAAndManage2FA();  // New function to verify and manage 2FA
    }
  }

  // Function to check 2FA before profile update
  const check2FAAndSaveProfile = async () => {
    if (twoFaMethod !== 'none') {
      setModalState('profile');
      if(wallet !== user.wallet || currency !== user.currency){
        setTwoFaCode('');
        setIs2FAModalOpen(true);
        if (twoFaMethod === 'email') {
          // Trigger the sending of 2FA code via email
          await axios.post('/api/send-2fa-code', { email: user.email });
        }
      } else {
        handleSave();
      }
    } else {
      // Proceed with normal profile update
      handleSave();
    }
  };

  // Function to verify 2FA code
  const verify2FAAndSaveProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/verify-2fa-profile', { email: user.email, code: twoFaCode });
      if (response.data.success) {
        setIs2FAModalOpen(false);
        handleSave();
      } else {
        // Handle verification failure
        setError('Invalid 2FA code');
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Rate limit exceeded, extract retry-after value if available
      const retryAfter = error.response.headers['retry-after'];
      const waitTimeMinutes = retryAfter ? Math.ceil(retryAfter / 60) : null; // Convert seconds to minutes
      const waitTimeMessage = waitTimeMinutes ? ` Please try again in ${waitTimeMinutes} minutes.` : '';
      setError(`Too many attempts.${waitTimeMessage}`);
      } else {
        setError('Error verifying 2FA code');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Please log in.');
      setShowErrorModal(true);
      return;
    }

    setIsLoading(true);

    // Validate wallet and currency
    try {
      const validateResponse = await axios.post('/api/validate-wallet', { wallet, currency });
      if (validateResponse.data !== 'OK') {
        setError('The wallet address and currency selected are not valid. Please check the wallet address and currency and try again.');
        setShowErrorModal(true);
        setIsLoading(false);
        return;
      }
    } catch (err) {
      setError(err.response?.data || 'Failed to validate wallet address.');
      setShowErrorModal(true);
      setIsLoading(false);
      return;
    }

    try {
      await axios.post('/api/update-profile', { username, wallet, currency }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUser({ ...user, username, wallet, currency });
      setEditMode(false);
      setModalState('');
      setIsLoading(false);

      if (window.gtag) {
        window.gtag('event', 'profile_update', {
          'event_category': 'User',
          'event_label': 'Profile Updated',
          'value': 1,
        });
      }
    } catch (err) {
      setError(err.response?.data || 'Failed to update profile');
      setShowErrorModal(true);
      setIsLoading(false);
    }
  };

  const handleCloseErrorModal = () => {
      setShowErrorModal(false);
      if (error === 'Please log in.' || error === 'Session expired. Please log in again.') {
        localStorage.removeItem('token'); // Remove the token from local storage
        setIsLoggedIn(false); // Update the isLoggedIn state
        window.location.href = '/register-login'; // Redirect to Register/Login page
      }
    };

    const handle2FAForPasswordChange = async () => {
      setIsLoading(true);
      try {
        if (twoFaMethod === 'none') {
          setIsChangePasswordModalOpen(true);  // Open the change password modal directly
        } else {
          if (twoFaMethod === 'email') {
            await axios.post('/api/send-2fa-code', { email });
          }
          setTwoFaCode('');
          setIs2FAModalOpen(true);  // Open the 2FA verification modal
          setIsChangePasswordModalOpen(false);  // Close the change password modal
        }

        if (window.gtag) {
          window.gtag('event', '2fa_initiated', {
              'event_category': 'Security',
              'event_label': '2FA Initiated',
              'value': 1,
          });
        }
      } catch (error) {
        console.error("Error sending 2FA code: ", error);
      } finally {
        setIsLoading(false);
      }
    };

  const verify2FAAndChangePassword = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/verify-2fa-profile', { email, code: twoFaCode });
      if (response.data.success) {
        setIs2FAModalOpen(false);
        setIsChangePasswordModalOpen(true);
        setModalState('');
        setIsLoading(false);
      } else {
        setError('Invalid 2FA code');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 429) {
        // Rate limit exceeded, extract retry-after value if available
        const retryAfter = error.response.headers['retry-after'];
        const waitTimeMinutes = retryAfter ? Math.ceil(retryAfter / 60) : null; // Convert seconds to minutes
        const waitTimeMessage = waitTimeMinutes ? ` Please try again in ${waitTimeMinutes} minutes.` : '';
        setError(`Too many attempts.${waitTimeMessage}`);
      } else {
        setError('Error verifying 2FA code');
      }
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    console.log('Starting password change...');
    setIsLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post('/api/change-password', { currentPassword, newPassword }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Password change successful:', response);
      setPasswordSuccess(response.data);
      setPasswordError('');
      setCurrentPassword('');
      setNewPassword('');
    } catch (err) {
      console.error('Password change failed:', err.response?.data);
      setPasswordError(err.response?.data || 'Failed to update password');
      setPasswordSuccess('');
    } finally {
      console.log('Finished password change');
      setIsLoading(false);
    }
  };

  const handle2FAForAccountDeletion = async () => {
    setIsLoading(true);
    try {
      if (twoFaMethod === 'none') {
        setIsAccountDeletionModalOpen(true);  // Open the account deletion modal directly
      } else {
        if (twoFaMethod === 'email') {
          await axios.post('/api/send-2fa-code', { email });
        }
        setTwoFaCode('');
        setIs2FAModalOpen(true);  // Open the 2FA verification modal
        setIsAccountDeletionModalOpen(false);  // Close the account deletion modal
      }
    } catch (error) {
      console.error("Error sending 2FA code: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const verify2FAAndDeleteAccount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/verify-2fa-profile', { email, code: twoFaCode });
      if (response.data.success) {
        setIs2FAModalOpen(false);
        setIsAccountDeletionModalOpen(true);
        setModalState('');
        setIsLoading(false);
      } else {
        setError('Invalid 2FA code');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 429) {
        // Rate limit exceeded, extract retry-after value if available
        const retryAfter = error.response.headers['retry-after'];
        const waitTimeMinutes = retryAfter ? Math.ceil(retryAfter / 60) : null; // Convert seconds to minutes
        const waitTimeMessage = waitTimeMinutes ? ` Please try again in ${waitTimeMinutes} minutes.` : '';
        setError(`Too many attempts.${waitTimeMessage}`);
      } else {
        setError('Error verifying 2FA code');
      }
    }
  };

  const handle2FAForManagement = async () => {
    setIsLoading(true);
    try {
      if (twoFaMethod === 'none') {
        setIsManage2FAModalOpen(true);  // Open the Manage 2FA modal directly
      } else {
        if (twoFaMethod === 'email') {
          await axios.post('/api/send-2fa-code', { email });
        }
        setTwoFaCode('');  // Reset the 2FA code input
        setIs2FAModalOpen(true);  // Open the 2FA verification modal
        setIsManage2FAModalOpen(false);  // Close the Manage 2FA modal
      }
    } catch (error) {
      console.error("Error sending 2FA code: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const verify2FAAndManage2FA = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/verify-2fa-profile', { email, code: twoFaCode });
      if (response.data.success) {
        setIs2FAModalOpen(false);  // Close the 2FA verification modal
        setIsManage2FAModalOpen(true);  // Open the Manage 2FA modal (which will be created)
        setModalState('');  // Reset the modal state
        setIsLoading(false);
      } else {
        setError('Invalid 2FA code');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 429) {
        // Rate limit exceeded, extract retry-after value if available
        const retryAfter = error.response.headers['retry-after'];
        const waitTimeMinutes = retryAfter ? Math.ceil(retryAfter / 60) : null; // Convert seconds to minutes
        const waitTimeMessage = waitTimeMinutes ? ` Please try again in ${waitTimeMinutes} minutes.` : '';
        setError(`Too many attempts.${waitTimeMessage}`);
      } else {
        setError('Error verifying 2FA code');
      }
    }
  };

  return (
    <div className="dashboard">
      {isLoading && <LoadingModal isOpen={isLoading} />}
      {showErrorModal && <ErrorModal message={error} onClose={handleCloseErrorModal} />}
      <img src={dashboardHeaderImage} alt="WinCoinDraw Dashboard, control your account and lottery tickets from here" className="dashboard-header-image"/>

      {/* Top Section with two side-by-side divs */}
      <div className="top-section">
        {/* Profile Section */}
        <div className="profile-section">
            <h2 className="section-title">PROFILE</h2>
            <div className="profile-info">
              <p>
                <strong className="labels">Username:&nbsp;</strong>
                {editMode ? (
                  <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                ) : (
                  user.username
                )}
              </p>
              <p>
                <strong className="labels">Email:</strong> {user.email}
              </p>
              <p>
                <strong className="labels">Wallet:&nbsp;</strong>
                {editMode ? (
                  <input type="text" value={wallet} onChange={(e) => setWallet(e.target.value)} />
                ) : (
                  user.wallet
                )}
              </p>
              <p>
                <strong className="labels">Currency:&nbsp;</strong>
                {editMode ? (
                  <Select value={supportedCurrencies.find(c => c.value === currency)} classNamePrefix="custom-select" onChange={(e) => setCurrency(e.value)} options={supportedCurrencies} />
                ) : (
                  currency.toUpperCase()
                )}
              </p>
            </div>
            {editMode ? (
              <div className="profile-actions">
                <button onClick={() => handleModalState('profile')} className="btn btn-save">Save</button>
                <button onClick={() => setEditMode(false)} className="btn btn-cancel">Cancel</button>
              </div>
            ) : (
              <button onClick={() => handleSensitiveAction('profile')} className="btn btn-edit">Edit Profile</button>
            )}
        </div>

        {/* Account Actions Section */}
        <div className="account-actions-section">
            <h2 className="section-title">ACCOUNT ACTIONS</h2>
            <button onClick={() => handleSensitiveAction('changePassword')} className="btn btn-change-password">Change Password</button>
            <ChangePasswordModal
              isOpen={isChangePasswordModalOpen}
              onClose={closeChangePasswordModal}
              setIsLoading={setIsLoading}
            />
            <button onClick={() => handleSensitiveAction('manage2FA')} className="btn btn-manage2fa">Manage Two-Factor Authentication</button>
            <TwoFAModal
              isOpen={isManage2FAModalOpen}
              onClose={closeManage2FAModal}
              email={email}
              twoFaMethod={twoFaMethod}
              setIsLoading={setIsLoading}
            />
            <button onClick={() => handleSensitiveAction('deleteAccount')} className="btn btn-delete">Delete Account</button>
            <AccountDeletionModal
              isOpen={isAccountDeletionModalOpen}
              onClose={closeAccountDeletionModal}
              onDelete={handleAccountDeletion}
              setIsLoading={setIsLoading}
            />
            <button onClick={handleLogout} className="btn btn-logout">Logout</button>
        </div>
      </div>

      {/* Bottom Section for Lottery Tickets */}
      <div className="bottom-section">
          {tickets.length > 0 ? (
            <>
            <div className="lottery-tickets-header">
              <h2 className="section-title">LOTTERY TICKETS</h2>
              <input
                value={globalFilter || ''}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder="SEARCH TICKETS"
                className="search-input"
              />
              </div>
              <div className="tickets-table-container">
                <table {...getTableProps()} className="tickets-table">
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={downArrow} alt="Descending" width="16" height="16" className="sort-arrow" />
                          ) : (
                            <img src={upArrow} alt="Ascending" width="16" height="16" className="sort-arrow" />
                          )
                        ) : (
                          ''
                        )}
                        </span>
                        </th>
                      ))}
                      </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                        })}
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                  </div>
                  <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      <img src={firstPageIcon} alt="Go to first page" className="pagination-icons" />
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                      <img src={prevPageIcon} alt="Go to previous page" className="pagination-icons" />
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                      <img src={nextPageIcon} alt="Go to next page" className="pagination-icons" />
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      <img src={lastPageIcon} alt="Go to last page" className="pagination-icons" />
                    </button>
                    <span className="gotopage-label">
                      Go to page:{' '}
                      <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                          const page = e.target.value ? Number(e.target.value) - 1 : 0;
                          gotoPage(page);
                        }}
                        className="gotopage-input"
                      />
                    </span>
                    <span className="page-options">
                      Page{' '}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{' '}
                    </span>
                    <div className="select-pagination">
                      <select
                        value={pageSize}
                        onChange={e => {
                          setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <p className="no-tickets-message">No tickets found. <a href="/buy-tickets" className="no-tickets-link">Buy tickets now</a>.</p>
              )}
      </div>
      {is2FAModalOpen && (
        <div className="two-fa-modal">
          <h2>Enter 2FA Code</h2>
          {error && <p className="error">{error}</p>}
          <input
            type="text"
            placeholder="Enter 2FA Code"
            value={twoFaCode}
            onChange={(e) => setTwoFaCode(e.target.value)}
          />
          <button className="btn btn-save" onClick={handleVerify}>{(modalState === 'profile' ? "Verify and Save" : "Proceed")}</button>
          <button className="btn btn-cancel" onClick={cancel2FA}>Cancel</button>
        </div>
      )}
      {is2FAModalOpen && (
        <div className="two-fa-modal-overlay"></div>
      )}
    </div>
  );
};

export default Dashboard;
