/* global gtag */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './WinnersTable.css';

const WinnersTable = () => {
  const [winners, setWinners] = useState([]);
  const [recordsToShow, setRecordsToShow] = useState(10);
  const [activeIndex, setActiveIndex] = useState(0);
  const cardsContainerRef = useRef(null);

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await axios.get('/api/winners');
        setWinners(response.data);
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    };

    fetchWinners();
  }, []);

  const handleNextCard = () => {
    if (activeIndex < winners.slice(0, recordsToShow).length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handlePrevCard = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const handleSwipe = (event) => {
    const container = cardsContainerRef.current;
    if (container) {
      const cardWidth = container.offsetWidth;
      const scrollPosition = container.scrollLeft;
      const newIndex = Math.round(scrollPosition / cardWidth);
      setActiveIndex(newIndex);
    }
  };

  useEffect(() => {
    const container = cardsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleSwipe);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleSwipe);
      }
    };
  }, []);

  useEffect(() => {
    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollLeft = activeIndex * cardsContainerRef.current.offsetWidth;
    }
  }, [activeIndex]);

  const handleTransactionLinkClick = (transactionLink) => {
    if (window.gtag) {
      window.gtag('event', 'transaction_link_click', {
        event_category: 'Winners',
        event_label: transactionLink,
      });
    }
  };

  return (
    <div className="winners-table-wrapper">
      <div className="winners-table-inner-container">
        <h2 className="winners-table-title">Recent Winners</h2>
        {winners.length > 0 ? (
          <>
            <div className="winners-table-controls">
              <label htmlFor="recordsToShow">Show records:</label>
              <select
                id="recordsToShow"
                onChange={(e) => setRecordsToShow(e.target.value)}
                value={recordsToShow}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={winners.length}>All</option>
              </select>
            </div>
            <div className="winners-table-container">
              <table className="winners-table">
                <thead className="winners-table-header">
                  <tr>
                    <th>Date</th>
                    <th>Username</th>
                    <th>Amount Won</th>
                    <th>Winning Number</th>
                    <th>Transaction Link</th>
                  </tr>
                </thead>
                <tbody>
                  {winners.slice(0, recordsToShow).map((winner, index) => (
                    <tr key={index} className="winners-table-row">
                      <td className="winners-table-cell">{new Date(winner.draw_date).toLocaleDateString()}</td>
                      <td className="winners-table-cell">{winner.username}</td>
                      <td className="winners-table-cell">
                        {Number(winner.amount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="winners-table-cell">{winner.winning_number}</td>
                      <td className="winners-table-cell">
                        {winner.transaction_link ? (
                        <a
                          href={winner.transaction_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => handleTransactionLinkClick(winner.transaction_link)}
                          >
                          View Transaction
                        </a>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="winners-cards-container" ref={cardsContainerRef}>
              {winners.slice(0, recordsToShow).map((winner, index) => (
                <div key={index} className="winner-card">
                  <h3>{new Date(winner.draw_date).toLocaleDateString()}</h3>
                  <p><strong>Username:</strong> {winner.username}</p>
                  <p><strong>Amount Won:</strong> {Number(winner.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  <p><strong>Winning Number:</strong><br /> {winner.winning_number}</p>
                  <p><strong>Transaction Link: </strong>
                    {winner.transaction_link ? (
                      <a
                        href={winner.transaction_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => handleTransactionLinkClick(winner.transaction_link)}
                      >
                        View Transaction
                        </a>
                    ) : (
                      'N/A'
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div className="card-indicators">
              {winners.slice(0, recordsToShow).map((_, index) => (
                <div
                  key={index}
                  className={`card-indicator ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => setActiveIndex(index)}
                ></div>
              ))}
            </div>
          </>
        ) : (
          <p>No winners yet. Be the first winner! <a href="/buy-tickets">Buy your tickets now</a>.</p>
        )}
      </div>
    </div>
  );
};

export default WinnersTable;
