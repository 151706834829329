/* global gtag */
import React from 'react';
import './TermsOfServicePage.css';
import termsHeaderImage from '../assets/images/termsHeaderImage.svg'; // Import the header image

const TermsOfServicePage = () => {
  const handleContactLinkClick = () => {
    if (window.gtag) {
      window.gtag('event', 'contact_link_click', {
        'event_category': 'Navigation',
        'event_label': 'Contact Link Clicked - Terms of Service Page',
        'value': 1,
      });
    }
  };

  return (
    <div className="terms-of-service-page">
      <img src={termsHeaderImage} alt="Terms of Service Header" className="terms-header-image" /> {/* Add header image */}

      <div className="terms-grid"> {/* Apply grid styling */}
        <div className="terms-section">
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using our website, you accept and agree to be bound by these terms of service. If you do not agree to these terms, please do not use our website.</p>
        </div>

        <div className="terms-section">
          <h2>2. Use of Our Service</h2>
          <p>You must be at least 18 years old to use our services. You agree to use our services only for lawful purposes and in accordance with these terms.</p>
        </div>

        <div className="terms-section">
          <h2>3. Account Registration</h2>
          <p>To participate in our lottery, you must create an account. You agree to provide accurate and complete information during registration and to keep your account information updated. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.</p>
        </div>

        <div className="terms-section">
          <h2>4. Payment Processing</h2>
          <p>We accept over 300 different cryptocurrencies via our payment processor NowPayments.io. All transactions are secure and transparent. You are responsible for ensuring that your payment information is accurate and complete.</p>
        </div>

        <div className="terms-section">
          <h2>5. User Conduct</h2>
          <p>You agree not to engage in any conduct that is harmful, unlawful, or disruptive to our service or to other users. Prohibited activities include, but are not limited to:</p>
          <ul>
            <li>Engaging in fraudulent activities.</li>
            <li>Impersonating any person or entity.</li>
            <li>Harassing, threatening, or defaming other users.</li>
            <li>Uploading or distributing any malicious software or other harmful content.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h2>6. Termination of Service</h2>
          <p>We reserve the right to terminate or suspend your account if you violate these terms of service or engage in any conduct that we determine to be inappropriate or harmful. We also reserve the right to terminate or suspend your account for any other reason at our sole discretion.</p>
        </div>

        <div className="terms-section">
          <h2>7. Limitation of Liability</h2>
          <p>We are not liable for any damages or losses arising from your use of our service. Our liability is limited to the maximum extent permitted by law. This includes, but is not limited to, any direct, indirect, incidental, consequential, or punitive damages.</p>
        </div>

        <div className="terms-section">
          <h2>8. Intellectual Property</h2>
          <p>All content and materials on our website, including text, graphics, logos, and images, are the property of WinCoinDraw or its licensors and are protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without our express written permission.</p>
        </div>

        <div className="terms-section">
          <h2>9. Changes to These Terms</h2>
          <p>We may update these terms of service from time to time. We will notify you of any changes by posting the new terms of service on our website. Your continued use of our service after any changes to these terms will constitute your acceptance of such changes.</p>
        </div>

        <div className="terms-section">
          <h2>10. Governing Law</h2>
          <p>These terms of service are governed by and construed in accordance with the laws of the jurisdiction in which WinCoinDraw operates, without regard to its conflict of law principles.</p>
        </div>

        <div className="terms-section">
          <h2>11. Contact Us</h2>
          <p>If you have any questions or concerns about these terms of service, please contact us via our <a href="/contact" onClick={handleContactLinkClick} className="contact-link">contact form</a>.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
