/* global gtag */
import React from 'react';
import './FAQPage.css';
import faqHeaderImage from '../assets/images/faqHeaderImage.svg';

const FAQPage = () => {
  const handleContactLinkClick = () => {
    if (window.gtag) {
      window.gtag('event', 'contact_link_click', {
        'event_category': 'Navigation',
        'event_label': 'Contact Link Clicked - FAQ Page',
        'value': 1,
      });
    }
  };

  return (
    <div className="faq-page">
      <img src={faqHeaderImage} alt="FAQ Header" className="faq-header-image" />

      <div className="faq-grid">
        <div className="faq-section">
          <h2>What is WinCoinDraw?</h2>
          <p>WinCoinDraw is a fully automated lottery site where you can buy lottery tickets for the price of 1 USDT each. We are fully transparent; you can see the wallet that holds the prize money live, and you can also see the transactions of the prizes being sent to the winners. There is a draw every Sunday at 17:00 UTC, so make sure to buy your tickets before then each week to have a chance to win.</p>
        </div>

        <div className="faq-section">
          <h2>How are the lottery draws conducted?</h2>
          <p>Our lottery draws are fully automated with no outside interference, ensuring fairness and transparency in the selection process.</p>
        </div>

        <div className="faq-section">
          <h2>What currencies are accepted for buying tickets?</h2>
          <p>We accept over 300 different cryptocurrencies through our payment processor NowPayments.io. You can choose from any of the popular cryptocurrencies and receive your winnings in the same currency you used to purchase the tickets.</p>
        </div>

        <div className="faq-section">
          <h2>What is the currency that has the lowest fees to buy tickets and receive my winnings?</h2>
          <p>The currency with the lowest fees using our platform is USDT TRC20 (TRON).</p>
        </div>

        <div className="faq-section">
          <h2>Why did I receive a little less than the amount in the current prize on the homepage?</h2>
          <p>This is because of the conversion and processing fees taken by the respective network for the currency you chose to receive your prize on. It shouldn’t be much, but could be as high as 20% or more for certain currencies. We recommend using either USDTTRC20 or USDTBSC to accept your payout as they have shown to have the lowest fees.</p>
        </div>

        <div className="faq-section">
          <h2>How do I register for WinCoinDraw?</h2>
          <p>To register, simply click on the register link, enter your username (this can be any name you want to display), email address, select your payout currency and protocol, enter a valid wallet address for that currency and protocol, and finally enter a password. Then, press the register button to complete the process.</p>
        </div>

        <div className="faq-section">
          <h2>How can I create a wallet or exchange account to participate in WinCoinDraw?</h2>
          <p>To participate in WinCoinDraw, you will need a cryptocurrency wallet or an account on a cryptocurrency exchange. Below are some recommended wallets and exchanges that are easy to use:</p>
          <ul>
            <li><a href="#binance">Binance</a></li>
            <li><a href="#coinbase">Coinbase</a></li>
            <li><a href="#metamask">MetaMask</a></li>
            <li><a href="#trustwallet">Trust Wallet</a></li>
          </ul>
        </div>

        <div className="faq-section" id="binance">
          <h2>How to Create a Binance Account</h2>
          <p>Binance is one of the largest cryptocurrency exchanges in the world. Follow the steps below to create an account:</p>
          <ol>
            <li>Visit <a href="https://www.binance.com" target="_blank" rel="noopener noreferrer">Binance.com</a>.</li>
            <li>Click on "Register" in the top right corner.</li>
            <li>Fill in your email address and create a password.</li>
            <li>Verify your email address and complete the identity verification process.</li>
            <li>Once verified, deposit funds into your Binance account to start purchasing cryptocurrency.</li>
          </ol>
        </div>

        <div className="faq-section" id="coinbase">
          <h2>How to Create a Coinbase Account</h2>
          <p>Coinbase is a beginner-friendly exchange that allows users to buy, sell, and store cryptocurrency. Here's how you can get started:</p>
          <ol>
            <li>Visit <a href="https://www.coinbase.com" target="_blank" rel="noopener noreferrer">Coinbase.com</a>.</li>
            <li>Click "Get Started" on the homepage.</li>
            <li>Enter your personal information and verify your identity.</li>
            <li>Link your bank account or credit card to make your first deposit.</li>
            <li>Purchase cryptocurrency and store it in your Coinbase account.</li>
          </ol>
        </div>

        <div className="faq-section" id="metamask">
          <h2>How to Set Up MetaMask Wallet</h2>
          <p>MetaMask is a popular browser-based cryptocurrency wallet. Here's how to set it up:</p>
          <ol>
            <li>Visit the official MetaMask website at <a href="https://metamask.io" target="_blank" rel="noopener noreferrer">MetaMask.io</a>.</li>
            <li>Download the MetaMask extension for your browser or the mobile app for your device.</li>
            <li>Create a new wallet by setting a password and securely storing your recovery phrase.</li>
            <li>Once set up, you can deposit cryptocurrency into your MetaMask wallet.</li>
          </ol>
        </div>

        <div className="faq-section" id="trustwallet">
          <h2>How to Set Up Trust Wallet</h2>
          <p>Trust Wallet is a mobile-based cryptocurrency wallet. Here's how to get started:</p>
          <ol>
            <li>Visit <a href="https://trustwallet.com" target="_blank" rel="noopener noreferrer">TrustWallet.com</a> and download the app for iOS or Android.</li>
            <li>Open the app and tap on "Create a New Wallet."</li>
            <li>Write down your recovery phrase and keep it safe.</li>
            <li>After setting up, you can deposit cryptocurrency into your Trust Wallet.</li>
          </ol>
        </div>

        <div className="faq-section">
          <h2>How do I participate in the lottery?</h2>
          <p>To participate in the lottery, go to the Buy Tickets page (once signed in), select the payment currency you'd like to use (there may be a minimum payment amount due to the nature of cryptocurrencies, usually around the equivalent of 7 USDT). Then, either enter the ticket numbers you'd like to use, generate them randomly, or mix both. Finally, click the buy tickets button and follow the link to complete the payment via NowPayments. Once the payment is confirmed, your tickets will be issued to your account, and you can see them on the account page.</p>
        </div>

        <div className="faq-section">
          <h2>How are winners selected?</h2>
          <p>Winners are selected automatically by the system, which randomly chooses the winning numbers. If that number matches yours, you win.</p>
        </div>

        <div className="faq-section">
          <h2>How do I receive my winnings?</h2>
          <p>The winner will automatically receive the winnings to the wallet address entered into their account. The wallet address can be changed at any time before the draw date and time.</p>
        </div>

        <div className="faq-section">
          <h2>Why is the Buy Tickets page forcing me to buy more than one ticket?</h2>
          <p>Due to the nature of cryptocurrencies, there is a minimum payment amount required by our payment processor to process transactions. Not to worry, the ticket price will always remain the same, which is the value and equivalent of 1 USDT per ticket. We also offer bundle deals on bulk orders.</p>
        </div>

        <div className="faq-section">
          <h2>Do you offer any bundles or discounts?</h2>
          <p>Yes, we offer bundle deals on bulk ticket purchases. Here are the current bundles:
            <ul>
              <li>Buy 5 tickets, get 1 free</li>
              <li>Buy 10 tickets, get 2 free</li>
              <li>Buy 25 tickets, get 5 free</li>
              <li>Buy 40 tickets, get 10 free</li>
            </ul>
            These bundles provide additional value and ensure you get more chances to win for your investment.
          </p>
        </div>

        <div className="faq-section">
          <h2>Is WinCoinDraw available worldwide?</h2>
          <p>WinCoinDraw is available worldwide. However, you should check the rules and regulations in your country to see if you can participate. WinCoinDraw is not responsible for the laws in your country.</p>
        </div>

        <div className="faq-section">
          <h2>Why is the draw scheduled exactly at 17:00 UTC?</h2>
          <p>We’ve set the draw time at 17:00 UTC to ensure fairness and transparency. This means that the draw is automatically triggered at this specific time without any human interference.</p>
        </div>

        <div className="faq-section">
          <h2>How long before 17:00 UTC should I buy my tickets?</h2>
          <p>We recommend that you buy your tickets at least 15 to 30 minutes before the draw time. This is because the purchase process, including payment confirmations, can take some time. Buying tickets too close to the draw time might prevent them from being processed in time, meaning they will be entered into the next draw instead.</p>
        </div>

        <div className="faq-section">
          <h2>How can I contact WinCoinDraw?</h2>
          <p>You can contact us using our <a href="/contact" onClick={handleContactLinkClick}>contact form</a>. We are here to help with any inquiries or issues you might have.</p>
        </div>

        <div className="faq-section">
          <h2>What if I have issues with my account or need assistance?</h2>
          <p>Please contact customer support for any issues or assistance you need. We are here to help ensure you have the best experience with WinCoinDraw.</p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
