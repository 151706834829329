// LoadingModal.js
import React from 'react';
import './LoadingModal.css'; // Ensure you have appropriate CSS for this

const LoadingModal = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="loading-modal">
      <div className="modal-content">
        <h2>Processing...</h2>
        <p>Please wait while we process your request.</p>
      </div>
    </div>
  );
};

export default LoadingModal;
