import React from 'react';
import './PrivacyPolicyPage.css';
import privacyHeaderImage from '../assets/images/privacyHeaderImage.svg'; // Image import

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-page">
      <img src={privacyHeaderImage} alt="Privacy Policy Header" className="privacy-header-image" />

      <div className="privacy-grid">
        <div className="privacy-section">
            <h2>1. Data Collection</h2>
            <p>We collect personal data to provide and improve our services. The data we collect includes:</p>
            <ul>
              <li><strong>Personal Information:</strong> Email address, username, wallet address.</li>
              <li><strong>Transaction Data:</strong> Records of your transactions on our platform.</li>
              <li><strong>Usage Data:</strong> Information about how you use our website and services, including your IP address, browser type, and device information.</li>
              <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to track your activity on our website and hold certain information.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2>2. Data Usage</h2>
            <p>We use the data we collect for the following purposes:</p>
            <ul>
              <li>To process your transactions and manage your account.</li>
              <li>To communicate with you about your account or transactions.</li>
              <li>To provide customer support and improve our services.</li>
              <li>To monitor the usage of our service and improve the user experience.</li>
              <li>To detect, prevent, and address technical issues.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2>3. Data Sharing</h2>
            <p>We do not share your personal data with any third parties except as necessary to process your payments via NowPayments.io. We may also share your data in the following circumstances:</p>
            <ul>
              <li><strong>With Service Providers:</strong> To facilitate our service, provide the service on our behalf, or perform service-related tasks.</li>
              <li><strong>For Legal Reasons:</strong> If required by law or in response to valid requests by public authorities.</li>
            </ul>
          </div>

          <div className="privacy-section">
            <h2>4. Data Security</h2>
            <p>We use reasonable and appropriate measures to protect your personal data from loss, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
          </div>

          <div className="privacy-section">
            <h2>5. Your Rights</h2>
            <p>You have the right to access, correct, or delete your personal data. You can also object to or restrict the processing of your data. To exercise these rights, please contact us.</p>
          </div>

          <div className="privacy-section">
            <h2>6. Data Retention</h2>
            <p>We retain your personal data only for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
          </div>

          <div className="privacy-section">
            <h2>7. International Data Transfers</h2>
            <p>Your information, including personal data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ.</p>
          </div>

          <div className="privacy-section">
            <h2>8. Changes to this Policy</h2>
            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website. We encourage you to review this policy periodically for any changes.</p>
          </div>

          <div className="privacy-section">
            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us via our <a href="/contact" className="contact-link">contact form</a>.</p>
          </div>
        </div>
    </div>
  );
};

export default PrivacyPolicyPage;
