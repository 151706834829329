/* global gtag */
import React, { useState } from 'react';
import axios from 'axios';
import './AccountDeletionModal.css';

const AccountDeletionModal = ({ isOpen, onClose, onDelete, setIsLoading }) => {
    const [step, setStep] = useState(1); // Step 1: Initial confirmation, Step 2: Warning about active tickets
    const [numTickets, setNumTickets] = useState(0);
    const [drawDate, setDrawDate] = useState('');

    const handleDelete = async () => {
      const token = localStorage.getItem('token');
      setIsLoading(true);

      try {
        const response = await axios.delete('/api/delete-account', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
            confirmed: false
        }
      });

      if (response.data.hasActiveTickets) {
        setNumTickets(response.data.numTickets);
        setDrawDate(response.data.drawDate);
        setStep(2); // Move to step 2
      } else {
        // Track the event when the user confirms deletion
        if (window.gtag) {
          window.gtag('event', 'delete_account', {
            'event_category': 'User',
            'event_label': 'Account Deletion Confirmed',
            'value': 1,
          });
        }
        onDelete();
      }
    } catch (err) {
      console.error('Failed to delete account:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinalDelete = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);

    try {
      await axios.delete('/api/delete-account', {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          confirmed: true
        }
      });
      // Track the event when the user confirms deletion
      if (window.gtag) {
        window.gtag('event', 'delete_account', {
          'event_category': 'User',
          'event_label': 'Account Deletion Confirmed',
          'value': 1,
        });
      }
      onDelete();
    } catch (err) {
      console.error('Failed to delete account:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  // Track the event when the delete account modal is opened
  if (window.gtag) {
    window.gtag('event', 'delete_account_initiated', {
      'event_category': 'User',
      'event_label': 'Account Deletion Modal Opened',
      'value': 1,
    });
  }

  return (
    <div className="modal-overlay">
      <div className="account-deletion-modal">
      {step === 1 ? (
        <>
          <h3>Delete Account</h3>
          <p>Are you sure you want to delete your account? This action cannot be undone.</p>
          <button onClick={handleDelete} className="btn btn-delete">Delete</button>
          <button onClick={onClose} className="btn btn-close">Cancel</button>
          </>
      ) : (
        <>
          <h3>Active Tickets Found</h3>
            <p>
              You have {numTickets} active ticket{numTickets > 1 ? 's' : ''} for the draw on {drawDate}.
              If you delete your account now, you will not be able to win. Are you sure you want to proceed?
            </p>
            <button onClick={handleFinalDelete} className="btn btn-delete">Proceed with Deletion</button>
            <button onClick={onClose} className="btn btn-close">Cancel</button>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountDeletionModal;
