/* global gtag */
import React, { useState } from 'react';
import axios from 'axios';
import './ContactPage.css';
import contactHeader from '../assets/images/contactPageHeader.svg';
import background from '../assets/images/Background.svg';
import LoadingModal from './LoadingModal'; // Import the LoadingModal

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to manage the loading modal

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Track the form submission attempt
    if (window.gtag) {
      window.gtag('event', 'contact_form_submission', {
        'event_category': 'Contact',
        'event_label': 'Form Submission Attempted',
        'value': 1,
      });
    }

    setIsLoading(true); // Show the loading modal when the form is submitted

    try {
      await axios.post('/api/send-email', {
        name,
        email,
        message,
      });

      setStatus('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');

      // Track the successful form submission
      if (window.gtag) {
        window.gtag('event', 'contact_form_submission', {
          'event_category': 'Contact',
          'event_label': 'Form Submission Successful',
          'value': 1,
        });
      }
    } catch (error) {
      setStatus('Failed to send the message. Please try again later.');

      // Track the failed form submission
      if (window.gtag) {
        window.gtag('event', 'exception', {
          'description': 'Contact Form Submission Failed',
          'fatal': false,
          'event_category': 'Contact',
          'event_label': 'Form Submission Failed',
        });
      }
    } finally {
      setIsLoading(false); // Hide the loading modal after the email is sent
    }
  };

  return (
    <div className="contact-page" style={{ backgroundImage: `url(${background})` }}>
      <div className="contact-header-holder">
      <img src={contactHeader} alt="Contact Us Header Image" className="contact-header" />
      </div>

      {/* Show loading modal when isLoading is true */}
      <LoadingModal isOpen={isLoading} />

      <form onSubmit={handleSubmit} className="contact-form-container">
        <div className="contact-form">
          {status && <p className="status-message">{status}</p>}
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="message-textarea"
                required
              ></textarea>
            </div>
          </div>
          <button type="submit" className="btn-contact">SEND MESSAGE</button>
        </form>
    </div>
  );
};

export default ContactPage;
