import React, { useState, useEffect, useRef } from 'react';
import './CountdownTimer.css';

const CountdownTimer = ({ onDrawTimeChange, onTimeLeftChange }) => {
  const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

  const calculateTimeLeft = () => {
    const now = new Date();
    let nextDraw = new Date(now);

    // Calculate the days until next Sunday
    const daysUntilNextSunday = (7 - now.getUTCDay()) % 7;

    // Check if today is Sunday
    if (daysUntilNextSunday === 0) {
      // If the current time is before or exactly 17:00 UTC, use today as the draw date
      if (now.getUTCHours() < 17 || (now.getUTCHours() === 17 && now.getUTCMinutes() < 1)) {
        nextDraw.setUTCDate(now.getUTCDate());
      } else {
        // If it's past 17:00 UTC, set the draw date to the next Sunday
        nextDraw.setUTCDate(now.getUTCDate() + 7);
      }
    } else {
      nextDraw.setUTCDate(now.getUTCDate() + daysUntilNextSunday);
    }

    // Set the draw time to 17:00 UTC
    nextDraw.setUTCHours(17, 0, 0, 0);

    const difference = nextDraw - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: addLeadingZero(Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: addLeadingZero(Math.floor((difference / 1000 / 60) % 60)),
        seconds: addLeadingZero(Math.floor((difference / 1000) % 60)),
      };
    }

    return { timeLeft, nextDraw, difference };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft().timeLeft);
  const [nextDrawDate, setNextDrawDate] = useState(calculateTimeLeft().nextDraw);
  const [difference, setDifference] = useState(calculateTimeLeft().difference);

  const requestRef = useRef();

  const updateTimer = () => {
    const { timeLeft, nextDraw, difference } = calculateTimeLeft();
    setTimeLeft(timeLeft);
    setNextDrawDate(nextDraw);
    setDifference(difference);

    const isDrawTime = difference <= 1 * 60 * 1000 && difference >= -1 * 60 * 1000;

      // Notify parent component about the draw time status
      if (onDrawTimeChange) {
        onDrawTimeChange(isDrawTime);
      }

      // Pass the current time left to the parent component
      if (onTimeLeftChange) {
        onTimeLeftChange(timeLeft);
      }

    requestRef.current = requestAnimationFrame(updateTimer);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateTimer);
    return () => cancelAnimationFrame(requestRef.current);
  }, [onDrawTimeChange, onTimeLeftChange]);

  return (
    <div className="countdown-timer">
      <h2>NEXT DRAW COUNTDOWN</h2>
      <div className="time">
        <span>{timeLeft.days}d </span>
        <span>{timeLeft.hours}h </span>
        <span>{timeLeft.minutes}m </span>
        <span>{timeLeft.seconds}s</span>
      </div>
      <div className="next-draw-date">
        <h3>Next Draw Date:</h3>
        <p>{nextDrawDate.toUTCString()}</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
